@import '../../globalStyles/variables.scss';

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7%;
}

.login__logo {
  img {
    width: 100px;
    height: 100px;
  }
}

.login__title {
  margin-top: 40px;
  h3 {
    font-family: $primaryFontFamily;
    font-weight: bold;
  }
}

.login__form {
  width: 40%;
  margin-top: 40px;
  min-width: 300px;
  max-width: 550px;
}

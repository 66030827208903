@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.videoDetail {
  margin-top: 80px;
  margin-bottom: 20px;
}

.videoDetail__bookCard {
  width: 480px;
  height: 300px;
  @include mediaQueryFrom($smallScreen) {
    width: 304px;
    height: 190px;
  }
}

.videoDetail__image {
  width: 100%;
  height: 100%;
}

.videoDetail__icon {
  margin-right: 10px;
}

.videoDetail__divider {
  margin-top: 40px;
}

.videoDetail__basicInfo {
  font-family: $primaryFontFamily;
  h2 {
    font-weight: bold;
    margin-top: 20px;

    @include mediaQueryFrom($smallScreen) {
      font-size: 22px;
    }
  }

  h4 {
    opacity: 0.7;
    @include mediaQueryFrom($smallScreen) {
      font-size: 18px;
    }
  }

  h6 {
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
  }
}

.videoDetail__discount {
  text-decoration: line-through;
}

.videoDetail__button {
  margin-top: 20px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $accentCholishnu !important;
  border: none !important;
  font-family: $primaryFontFamily;
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
}

.videoDetail__authorInfo {
  font-family: $primaryFontFamily;
  margin-top: 10px;
  h6 {
    font-weight: bold;
  }
}

.videoDetail__description {
  font-family: $primaryFontFamily;
  margin-top: 40px;
}

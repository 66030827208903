@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.billingDetails {
  margin-bottom: 20px;
}

.billingDetails__label {
  font-weight: bold;
}

.billingDetails__phoneInput {
  input {
    height: 35px;
  }
}

.billingDetails__button {
  margin-top: 20px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $accentCholishnu !important;
  border: none !important;
  font-family: $primaryFontFamily;
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.searchItem {
  margin-bottom: 40px;
  margin-right: 40px;

  @include mediaQueryFrom($smallScreen) {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 5px 9px 15px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 9px 15px 1px rgba(0, 0, 0, 0.3);
  }
}

.searchItem__image {
  width: 100%;
  height: 100%;
}

@import '../../globalStyles/variables.scss';

.registerForm__label {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.registerForm__button {
  width: 100%;
  margin-top: 10px;
  background-color: $primaryCholishnu !important;
  font-family: 'Poppins', sans-serif;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.registerForm__spinner {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-svg {
    color: $primaryCholishnu;
  }
}

.registerForm__linkTextContainer {
  margin-top: 10px;
}

.registerForm__link {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
}

.registerForm__dateOfBirth {
  display: flex;
  flex-direction: column;
}

.registerForm__phoneInput {
  input {
    height: 35px;
  }
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.orderDetailItem {
  margin-bottom: 20px;
  font-family: $primaryFontFamily;
}

.orderDetailItem__infoTitle {
  font-weight: bold;
}

.orderDetailItem__discount {
  color: red;
}

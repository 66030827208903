@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.orderHistoryItem {
  margin-bottom: 20px;
  font-family: $primaryFontFamily;
}

.orderHistoryItem__title {
  color: $accentCholishnu;
}

.orderHistoryItem__infoTitle {
  font-weight: bold;
}

.orderHistoryItem__discount {
  color: red;
}

.orderHistoryItem__billingInfo {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.banner {
  h2 {
    font-family: $primaryFontFamily;
    font-weight: bold;
  }

  p {
    font-family: $primaryFontFamily;
  }
}

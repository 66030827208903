@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.profileDetailCard {
  font-family: $primaryFontFamily;
  min-width: 320px !important;
}

.profileDetailCard__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.profileDetailCard__infoTitle {
  font-weight: bold;
  margin-right: 20px;
}

.profileDetailCard__button {
  background-color: $accentCholishnu !important;
  border: none !important;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.featureItem {
  width: 480px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 5px 9px 15px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 9px 15px 1px rgba(0, 0, 0, 0.3);

    .featureItem__addToCart {
      opacity: 1;
    }
  }

  // @include mediaQueryFrom($mediumScreen) {
  //   width: 300px;
  //   height: 187px;
  // }

  @include mediaQueryFrom($smallScreen) {
    width: 400px;
    height: 250px;
  }
}

.featureItem__coverArt {
  height: 100%;
  width: 250px;
  background-color: $accentCholishnu;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featureItem__image {
  border: black 0.5px solid;
}

.featureItem__info {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  font-family: $primaryFontFamily;

  h5 {
    text-align: left;
  }
}

.featureItem__addToCart {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: grey;
  opacity: 0;
  transition: opacity ease-in-out 300ms;
  font-family: $primaryFontFamily;
  color: white;

  &:hover {
    background-color: $accentCholishnu;
    transition: background-color ease-in-out 100ms;
  }

  @include mediaQueryFrom($smallScreen) {
    font-size: 13px;
  }
}

.featureItem__icon {
  margin-right: 10px;
}

.featureItem__discount {
  text-decoration: line-through;
}

@import '../../globalStyles/variables.scss';

.appBar {
  background: $primaryCholishnu !important;
  font-family: $primaryFontFamily;
}

.appBar__brand {
  color: white !important;

  img {
    width: 120px;
    height: 30px;
  }
}

.appBar__link {
  color: white !important;
}

.appBar__user {
  display: flex;
  align-items: center;

  .dropdown-toggle {
    color: white !important;
    text-decoration: none !important;
  }
}

.appBar__iconContainer {
  position: relative;
}

.appBar__badge {
  position: absolute;
  left: 12px;
  top: -8px;
}

.appBar__icon {
  color: white !important;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

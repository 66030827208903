@import '../../globalStyles/variables.scss';

.loginForm__label {
  font-family: $primaryFontFamily;
  font-weight: bold;
}

.loginForm__button {
  width: 100%;
  margin-top: 10px;
  background-color: $primaryCholishnu !important;
  font-family: $primaryFontFamily;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.loginForm__spinner {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-svg {
    color: $primaryCholishnu;
  }
}

.loginForm__linkTextContainer {
  margin-top: 10px;
}

.loginForm__link {
  font-family: $primaryFontFamily;
  font-weight: bold;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
}

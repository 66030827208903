@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.profileAvatarCard {
  font-family: $primaryFontFamily;
  width: 100%;
  min-width: 320px !important;
}

.profileAvatarCard__avatarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.profileAvatarCard__name {
  font-weight: bold !important;
}

.profileAvatarCard__avatar {
  width: 100px !important;
  height: 100px !important;
  font-size: 30px !important;
  background-color: $primaryCholishnu !important;
}

.profileAvatarCard__button {
  background-color: $accentCholishnu !important;
  border: none !important;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.shippingDetails {
  margin-bottom: 40px;
}

.shippingDetails__label {
  font-weight: bold;
}

.shippingDetails__phoneInput {
  input {
    height: 35px;
  }
}

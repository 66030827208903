@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.paymentFailed {
  margin-top: 40px;
  font-family: $primaryFontFamily;
  max-width: 800px !important;
}

.paymentFailed__title {
  font-size: 30px !important;
  margin-bottom: 30px;
}

.paymentFailed__icon {
  font-size: 100px !important;
  color: red;
}

.paymentFailed__button {
  margin-top: 20px;
  width: 220px !important;
  height: 40px !important;
  background-color: $primaryCholishnu !important;
  border: none !important;
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
}

@import '../../globalStyles/variables.scss';

.footer {
  width: 100%;
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $primaryDarkCholishnu;
  color: white;
  margin-top: 20px;
  font-family: $primaryFontFamily;
}

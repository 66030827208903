@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.noDataFound {
  font-family: $primaryFontFamily;
  opacity: 0.6;
  margin-top: 40px;
}

.noDataFound__container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

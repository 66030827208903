@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.cartSummary__price {
  margin-left: 20px;

  &.discount {
    color: red;
  }
}

.cartSummary__title {
  font-weight: bold !important;

  span {
    font-weight: normal;
    margin-left: 20px;
  }
}

.cartSummary__button {
  background-color: $accentCholishnu !important;
  border: none !important;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

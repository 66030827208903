@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.genreList {
  display: flex;
  width: 100%;
}

.genreList__genre {
  margin-right: 20px;
  white-space: nowrap;
  font-family: $primaryFontFamily;
  opacity: 0.3;
  transition: font-size ease-in-out 200ms;

  &:hover {
    cursor: pointer;
    font-size: 18px;
  }
}

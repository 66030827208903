$lightGreyBackground: #efefef;
$primaryCholishnu: #054bc8;
$primaryDarkCholishnu: #011331;
$accentCholishnu: #fcb247;

$primaryFontFamily: 'Poppins', sans-serif;

$smallScreen: 600px;
$mediumScreen: 960px;
$largeScreen: 1280px;
$extraLargeScreen: 1920px;

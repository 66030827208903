@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.searchMedia {
  margin-top: 40px;
}

.searchMedia__searchResultInfo {
  font-family: $primaryFontFamily;
  margin-top: 40px;

  h5 {
    font-weight: bold;
  }
}

.searchMedia__itemContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.searchMedia__pagination {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.playlist_songList {
  font-family: $primaryFontFamily;
  margin-top: 20px;
}

.playlist_songItem {
  font-family: $primaryFontFamily;
  padding: 10px;
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.orderHistory {
  margin-top: 40px;
  font-family: $primaryFontFamily;
}

.orderHistory__title {
  margin-bottom: 20px;
}

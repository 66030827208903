@mixin mediaQueryTo($to) {
  @media (min-width: $to) {
    @content;
  }
}

@mixin mediaQueryFrom($from) {
  @media (max-width: $from) {
    @content;
  }
}

@mixin primaryFontStyle($fontSize, $fontColor) {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: $fontSize;
  color: $fontColor;
}

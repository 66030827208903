@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.audioItem {
  width: 250px;
  height: 250px;
  position: relative;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 5px 9px 15px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 9px 15px 1px rgba(0, 0, 0, 0.3);

    .audioItem__addToCart {
      opacity: 1;
    }
  }

  @include mediaQueryFrom($mediumScreen) {
    width: 200px;
    height: 200px;
  }

  @include mediaQueryFrom($smallScreen) {
    width: 150px;
    height: 150px;
  }
}

.audioItem__image {
  width: 100%;
  height: 100%;
}

.audioItem__addToCart {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: grey;
  opacity: 0;
  transition: opacity ease-in-out 300ms;
  font-family: $primaryFontFamily;
  color: white;

  &:hover {
    background-color: $accentCholishnu;
    transition: background-color ease-in-out 100ms;
  }

  @include mediaQueryFrom($smallScreen) {
    font-size: 13px;
  }

  &.delete {
    &:hover {
      background-color: red;
      transition: background-color ease-in-out 100ms;
    }
  }
}

.audioItem__icon {
  margin-right: 10px;
}

@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.about {
  font-family: $primaryFontFamily;
  margin-bottom: 20px;
}

.about__imageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.about__image {
  max-height: 500px;
  border-radius: 5px;
}

.about__titleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.about__paraContainer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.about__para {
  width: 70%;

  @include mediaQueryFrom($mediumScreen) {
    width: 100%;
  }
}

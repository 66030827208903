@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap);
body{margin:0;font-family:'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#efefef !important}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}::-webkit-scrollbar{display:none}


.bookItem{width:250px;height:375px;position:relative}.bookItem:hover{cursor:pointer;box-shadow:5px 9px 15px 1px rgba(0,0,0,0.3)}.bookItem:hover .bookItem__addToCart{opacity:1}@media (max-width: 960px){.bookItem{width:200px;height:300px}}@media (max-width: 600px){.bookItem{width:150px;height:225px}}.bookItem__image{width:100%;height:100%}.bookItem__addToCart{position:absolute;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;bottom:0;width:100%;height:50px;background-color:grey;opacity:0;transition:opacity ease-in-out 300ms;font-family:"Poppins",sans-serif;color:white}.bookItem__addToCart:hover{background-color:#fcb247;transition:background-color ease-in-out 100ms}@media (max-width: 600px){.bookItem__addToCart{font-size:13px}}.bookItem__addToCart.delete:hover{background-color:red;transition:background-color ease-in-out 100ms}.bookItem__icon{margin-right:10px}

.bookRibbon{display:-webkit-flex;display:flex;overflow-y:hidden;padding:10px 0;padding-right:20px}.bookRibbon__item{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;margin-left:30px}@media (max-width: 600px){.bookRibbon__item{margin-left:15px}}.bookRibbon__item h5{font-family:"Poppins",sans-serif;margin-top:20px;font-weight:bold;text-align:center}.bookRibbon__item p{font-family:"Poppins",sans-serif;font-size:16px;opacity:0.7}.bookRibbon__discount{text-decoration:line-through}

.audioItem{width:250px;height:250px;position:relative}.audioItem:hover{cursor:pointer;box-shadow:5px 9px 15px 1px rgba(0,0,0,0.3)}.audioItem:hover .audioItem__addToCart{opacity:1}@media (max-width: 960px){.audioItem{width:200px;height:200px}}@media (max-width: 600px){.audioItem{width:150px;height:150px}}.audioItem__image{width:100%;height:100%}.audioItem__addToCart{position:absolute;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;bottom:0;width:100%;height:50px;background-color:grey;opacity:0;transition:opacity ease-in-out 300ms;font-family:"Poppins",sans-serif;color:white}.audioItem__addToCart:hover{background-color:#fcb247;transition:background-color ease-in-out 100ms}@media (max-width: 600px){.audioItem__addToCart{font-size:13px}}.audioItem__addToCart.delete:hover{background-color:red;transition:background-color ease-in-out 100ms}.audioItem__icon{margin-right:10px}

.audioRibbon{display:-webkit-flex;display:flex;overflow-y:hidden;padding:10px 0;padding-right:20px}.audioRibbon__item{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;margin-left:30px}@media (max-width: 600px){.audioRibbon__item{margin-left:15px}}.audioRibbon__item h5{font-family:"Poppins",sans-serif;margin-top:20px;font-weight:bold;text-align:center}.audioRibbon__item p{font-family:"Poppins",sans-serif;font-size:16px;opacity:0.7}.audioRibbon__discount{text-decoration:line-through}

.videoItem{width:350px;height:218px;position:relative}.videoItem:hover{cursor:pointer;box-shadow:5px 9px 15px 1px rgba(0,0,0,0.3)}.videoItem:hover .videoItem__addToCart{opacity:1}@media (max-width: 960px){.videoItem{width:300px;height:187px}}@media (max-width: 600px){.videoItem{width:280px;height:175px}}.videoItem__image{width:100%;height:100%}.videoItem__addToCart{position:absolute;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;bottom:0;width:100%;height:50px;background-color:grey;opacity:0;transition:opacity ease-in-out 300ms;font-family:"Poppins",sans-serif;color:white}.videoItem__addToCart:hover{background-color:#fcb247;transition:background-color ease-in-out 100ms}@media (max-width: 600px){.videoItem__addToCart{font-size:13px}}.videoItem__addToCart.delete:hover{background-color:red;transition:background-color ease-in-out 100ms}.videoItem__icon{margin-right:10px}

.videoRibbon{display:-webkit-flex;display:flex;overflow-y:hidden;padding:10px 0;padding-right:20px}.videoRibbon__item{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;margin-left:30px}@media (max-width: 600px){.videoRibbon__item{margin-left:15px}}.videoRibbon__item h5{font-family:"Poppins",sans-serif;margin-top:20px;font-weight:bold;text-align:center}.videoRibbon__item p{font-family:"Poppins",sans-serif;font-size:16px;opacity:0.7}.videoRibbon__discount{text-decoration:line-through}

.dataLoading{padding-top:90px;height:100vh;width:100%;display:-webkit-flex;display:flex;background-color:#efefef;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center}.dataLoading__itemContainer{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.dataLoading__spinner{color:#054bc8 !important;margin-bottom:40px}

.banner h2{font-family:"Poppins",sans-serif;font-weight:bold}.banner p{font-family:"Poppins",sans-serif}

.genreList{display:-webkit-flex;display:flex;width:100%}.genreList__genre{margin-right:20px;white-space:nowrap;font-family:"Poppins",sans-serif;opacity:0.3;transition:font-size ease-in-out 200ms}.genreList__genre:hover{cursor:pointer;font-size:18px}

.noDataFound{font-family:"Poppins",sans-serif;opacity:0.6;margin-top:40px}.noDataFound__container{display:-webkit-flex;display:flex;width:100%;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}

.login{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-top:7%}.login__logo img{width:100px;height:100px}.login__title{margin-top:40px}.login__title h3{font-family:"Poppins",sans-serif;font-weight:bold}.login__form{width:40%;margin-top:40px;min-width:300px;max-width:550px}

.loginForm__label{font-family:"Poppins",sans-serif;font-weight:bold}.loginForm__button{width:100%;margin-top:10px;background-color:#054bc8 !important;font-family:"Poppins",sans-serif}.loginForm__button:hover{cursor:pointer;-webkit-filter:brightness(1.2);filter:brightness(1.2)}.loginForm__spinner{width:100%;margin-top:20px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.loginForm__spinner .MuiCircularProgress-svg{color:#054bc8}.loginForm__linkTextContainer{margin-top:10px}.loginForm__link{font-family:"Poppins",sans-serif;font-weight:bold;margin-bottom:10px}.loginForm__link:hover{cursor:pointer}

.featureRibbon{display:-webkit-flex;display:flex;overflow-y:hidden;padding:10px 0;padding-right:20px}.featureRibbon__item{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;margin-left:30px}@media (max-width: 600px){.featureRibbon__item{margin-left:15px}}.featureRibbon__item h5{font-family:"Poppins",sans-serif;margin-top:20px;font-weight:bold;text-align:center}.featureRibbon__item p{font-family:"Poppins",sans-serif;font-size:16px;opacity:0.7}

.featureItem{width:480px;height:300px;position:relative;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.featureItem:hover{cursor:pointer;box-shadow:5px 9px 15px 1px rgba(0,0,0,0.3)}.featureItem:hover .featureItem__addToCart{opacity:1}@media (max-width: 600px){.featureItem{width:400px;height:250px}}.featureItem__coverArt{height:100%;width:250px;background-color:#fcb247;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.featureItem__image{border:black 0.5px solid}.featureItem__info{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;margin-left:30px;height:100%;-webkit-justify-content:center;justify-content:center;-webkit-align-items:flex-start;align-items:flex-start;font-family:"Poppins",sans-serif}.featureItem__info h5{text-align:left}.featureItem__addToCart{position:absolute;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;bottom:0;width:100%;height:50px;background-color:grey;opacity:0;transition:opacity ease-in-out 300ms;font-family:"Poppins",sans-serif;color:white}.featureItem__addToCart:hover{background-color:#fcb247;transition:background-color ease-in-out 100ms}@media (max-width: 600px){.featureItem__addToCart{font-size:13px}}.featureItem__icon{margin-right:10px}.featureItem__discount{text-decoration:line-through}

.books{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}.books__search{padding-top:20px;padding-bottom:20px}.books__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.books__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.books__ribbonHeader{margin-top:0}}.books__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.books__ribbon{margin-bottom:0;margin-top:0}}.books__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}.books__genre{margin-bottom:30px;margin-top:30px;width:90%;overflow-x:auto;-webkit-align-self:center;align-self:center;display:-webkit-flex;display:flex;-webkit-align-items:flex-start;align-items:flex-start}

.videos{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}.videos__search{padding-top:20px;padding-bottom:20px}.videos__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.videos__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.videos__ribbonHeader{margin-top:0}}.videos__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.videos__ribbon{margin-bottom:0;margin-top:0}}.videos__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}.videos__genre{margin-bottom:30px;margin-top:30px;width:90%;overflow-x:auto;-webkit-align-self:center;align-self:center;display:-webkit-flex;display:flex;-webkit-align-items:flex-start;align-items:flex-start}

.audios{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}.audios__search{padding-top:20px;padding-bottom:20px}.audios__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.audios__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.audios__ribbonHeader{margin-top:0}}.audios__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.audios__ribbon{margin-bottom:0;margin-top:0}}.audios__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}.audios__genre{margin-bottom:30px;margin-top:30px;width:90%;overflow-x:auto;-webkit-align-self:center;align-self:center;display:-webkit-flex;display:flex;-webkit-align-items:flex-start;align-items:flex-start}

.register{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-top:40px}.register__logo img{width:100px;height:100px}.register__title{margin-top:40px}.register__title h3{font-size:25px;font-weight:bold}.register__form{width:40%;margin-top:40px;min-width:300px;max-width:550px}

.registerForm__label{font-family:'Poppins', sans-serif;font-weight:bold}.registerForm__button{width:100%;margin-top:10px;background-color:#054bc8 !important;font-family:'Poppins', sans-serif}.registerForm__button:hover{cursor:pointer;-webkit-filter:brightness(1.2);filter:brightness(1.2)}.registerForm__spinner{width:100%;margin-top:20px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.registerForm__spinner .MuiCircularProgress-svg{color:#054bc8}.registerForm__linkTextContainer{margin-top:10px}.registerForm__link{font-family:'Poppins', sans-serif;font-weight:bold;margin-bottom:10px}.registerForm__link:hover{cursor:pointer}.registerForm__dateOfBirth{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.registerForm__phoneInput input{height:35px}

.forgetPassword{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-top:7%}.forgetPassword__logo img{width:100px;height:100px}.forgetPassword__title{margin-top:40px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.forgetPassword__title h4{font-weight:bold;font-family:"Poppins",sans-serif}.forgetPassword__title p{font-family:"Poppins",sans-serif;margin-top:5px;text-align:center}.forgetPassword__form{width:40%;margin-top:40px;min-width:300px;max-width:550px}

.forgetPasswordForm__label{font-family:"Poppins",sans-serif;font-weight:bold}.forgetPasswordForm__button{width:100%;margin-top:10px;background-color:#054bc8 !important;font-family:"Poppins",sans-serif}.forgetPasswordForm__button:hover{cursor:pointer;-webkit-filter:brightness(1.2);filter:brightness(1.2)}.forgetPasswordForm__spinner{width:100%;margin-top:20px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.forgetPasswordForm__spinner .MuiCircularProgress-svg{color:#054bc8}.forgetPasswordForm__linkTextContainer{margin-top:10px}.forgetPasswordForm__link{font-family:"Poppins",sans-serif;font-weight:bold;margin-bottom:10px}.forgetPasswordForm__link:hover{cursor:pointer}

.bookDetail{margin-top:80px;margin-bottom:20px}.bookDetail__bookCard{width:320px;height:480px}@media (max-width: 600px){.bookDetail__bookCard{width:250px;height:375px}}.bookDetail__image{width:100%;height:100%}.bookDetail__icon{margin-right:10px}.bookDetail__divider{margin-top:40px}.bookDetail__basicInfo{font-family:"Poppins",sans-serif}.bookDetail__basicInfo h2{font-weight:bold;margin-top:20px}@media (max-width: 600px){.bookDetail__basicInfo h2{font-size:22px}}.bookDetail__basicInfo h4{opacity:0.7}@media (max-width: 600px){.bookDetail__basicInfo h4{font-size:18px}}.bookDetail__basicInfo h6{margin-top:40px;font-size:20px;font-weight:bold}.bookDetail__discount{text-decoration:line-through}.bookDetail__button{margin-top:20px;width:220px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:40px;background-color:#fcb247 !important;border:none !important;font-family:"Poppins",sans-serif}.bookDetail__button:hover{cursor:pointer;-webkit-filter:brightness(1.1);filter:brightness(1.1)}.bookDetail__authorInfo{font-family:"Poppins",sans-serif;margin-top:10px}.bookDetail__authorInfo h6{font-weight:bold}.bookDetail__description{font-family:"Poppins",sans-serif;margin-top:40px}

.bookById{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}.bookById__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.bookById__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.bookById__ribbonHeader h3{font-size:20px}}@media (max-width: 600px){.bookById__ribbonHeader{margin-top:0}}.bookById__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.bookById__ribbon{margin-bottom:0;margin-top:0}}.bookById__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}

.videoDetail{margin-top:80px;margin-bottom:20px}.videoDetail__bookCard{width:480px;height:300px}@media (max-width: 600px){.videoDetail__bookCard{width:304px;height:190px}}.videoDetail__image{width:100%;height:100%}.videoDetail__icon{margin-right:10px}.videoDetail__divider{margin-top:40px}.videoDetail__basicInfo{font-family:"Poppins",sans-serif}.videoDetail__basicInfo h2{font-weight:bold;margin-top:20px}@media (max-width: 600px){.videoDetail__basicInfo h2{font-size:22px}}.videoDetail__basicInfo h4{opacity:0.7}@media (max-width: 600px){.videoDetail__basicInfo h4{font-size:18px}}.videoDetail__basicInfo h6{margin-top:40px;font-size:20px;font-weight:bold}.videoDetail__discount{text-decoration:line-through}.videoDetail__button{margin-top:20px;width:220px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:40px;background-color:#fcb247 !important;border:none !important;font-family:"Poppins",sans-serif}.videoDetail__button:hover{cursor:pointer;-webkit-filter:brightness(1.1);filter:brightness(1.1)}.videoDetail__authorInfo{font-family:"Poppins",sans-serif;margin-top:10px}.videoDetail__authorInfo h6{font-weight:bold}.videoDetail__description{font-family:"Poppins",sans-serif;margin-top:40px}

.videoById{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}.videoById__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.videoById__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.videoById__ribbonHeader h3{font-size:20px}}@media (max-width: 600px){.videoById__ribbonHeader{margin-top:0}}.videoById__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.videoById__ribbon{margin-bottom:0;margin-top:0}}.videoById__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}

.audioDetail{margin-top:80px;margin-bottom:20px}.audioDetail__bookCard{width:480px;height:480px}@media (max-width: 600px){.audioDetail__bookCard{width:304px;height:304px}}.audioDetail__image{width:100%;height:100%}.audioDetail__icon{margin-right:10px}.audioDetail__divider{margin-top:40px}.audioDetail__basicInfo{font-family:"Poppins",sans-serif}.audioDetail__basicInfo h2{font-weight:bold;margin-top:20px}@media (max-width: 600px){.audioDetail__basicInfo h2{font-size:22px}}.audioDetail__basicInfo h4{opacity:0.7}@media (max-width: 600px){.audioDetail__basicInfo h4{font-size:18px}}.audioDetail__basicInfo h6{margin-top:40px;font-size:20px;font-weight:bold}.audioDetail__discount{text-decoration:line-through}.audioDetail__button{margin-top:20px;width:220px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:40px;background-color:#fcb247 !important;border:none !important;font-family:"Poppins",sans-serif}.audioDetail__button:hover{cursor:pointer;-webkit-filter:brightness(1.1);filter:brightness(1.1)}.audioDetail__authorInfo{font-family:"Poppins",sans-serif;margin-top:10px}.audioDetail__authorInfo h6{font-weight:bold}.audioDetail__description{font-family:"Poppins",sans-serif;margin-top:40px}

.audioById{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}

.playlist_songList{font-family:"Poppins",sans-serif;margin-top:20px}.playlist_songItem{font-family:"Poppins",sans-serif;padding:10px}

.searchMedia{margin-top:40px}.searchMedia__searchResultInfo{font-family:"Poppins",sans-serif;margin-top:40px}.searchMedia__searchResultInfo h5{font-weight:bold}.searchMedia__itemContainer{display:-webkit-flex;display:flex;width:100%;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.searchMedia__pagination{display:-webkit-flex;display:flex;width:100%;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-top:20px;margin-bottom:20px}

.searchItem{margin-bottom:40px;margin-right:40px}@media (max-width: 600px){.searchItem{margin-bottom:10px;margin-right:10px}}.searchItem:hover{cursor:pointer;box-shadow:5px 9px 15px 1px rgba(0,0,0,0.3)}.searchItem__image{width:100%;height:100%}

.profile__profileInfo{margin-top:40px}

.profileAvatarCard{font-family:"Poppins",sans-serif;width:100%;min-width:320px !important}.profileAvatarCard__avatarContainer{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;padding-top:20px}.profileAvatarCard__name{font-weight:bold !important}.profileAvatarCard__avatar{width:100px !important;height:100px !important;font-size:30px !important;background-color:#054bc8 !important}.profileAvatarCard__button{background-color:#fcb247 !important;border:none !important}.profileAvatarCard__button:hover{cursor:pointer;-webkit-filter:brightness(1.2);filter:brightness(1.2)}

.profileDetailCard{font-family:"Poppins",sans-serif;min-width:320px !important}.profileDetailCard__info{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;align-items:center;margin-bottom:20px}.profileDetailCard__infoTitle{font-weight:bold;margin-right:20px}.profileDetailCard__button{background-color:#fcb247 !important;border:none !important}.profileDetailCard__button:hover{cursor:pointer;-webkit-filter:brightness(1.2);filter:brightness(1.2)}

.library{margin-top:40px}.library__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.library__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.library__ribbonHeader{margin-top:0}}.library__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.library__ribbon{margin-bottom:0;margin-top:0}}.library__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}

.orderHistory{margin-top:40px;font-family:"Poppins",sans-serif}.orderHistory__title{margin-bottom:20px}

.orderHistoryItem{margin-bottom:20px;font-family:"Poppins",sans-serif}.orderHistoryItem__title{color:#fcb247}.orderHistoryItem__infoTitle{font-weight:bold}.orderHistoryItem__discount{color:red}.orderHistoryItem__billingInfo{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;margin-top:-10px}


.orderDetailItem{margin-bottom:20px;font-family:"Poppins",sans-serif}.orderDetailItem__infoTitle{font-weight:bold}.orderDetailItem__discount{color:red}

.cartItems__button{margin-left:20px}

.individualItem__itemRow{padding-top:20px;padding-bottom:20px}.individualItem__title{font-weight:bold}@media (max-width: 600px){.individualItem__title{margin-top:20px}}.individualItem__price{margin-top:20px}.individualItem__remove{margin-top:10px;color:red}.individualItem__remove:hover{cursor:pointer}.individualItem__quantityCol{display:-webkit-flex;display:flex;-webkit-align-items:flex-end;align-items:flex-end}@media (max-width: 600px){.individualItem__quantityCol{margin-top:20px}}.individualItem__quantity{display:-webkit-flex;display:flex;height:40px;-webkit-align-items:center;align-items:center}.individualItem__quantityValue{margin-right:20px;margin-left:20px}.individualItem__discount{text-decoration:line-through}

.cartSummary__price{margin-left:20px}.cartSummary__price.discount{color:red}.cartSummary__title{font-weight:bold !important}.cartSummary__title span{font-weight:normal;margin-left:20px}.cartSummary__button{background-color:#fcb247 !important;border:none !important}.cartSummary__button:hover{cursor:pointer;-webkit-filter:brightness(1.2);filter:brightness(1.2)}

.cart{font-family:"Poppins",sans-serif;margin-top:40px}

.billingDetails{margin-bottom:20px}.billingDetails__label{font-weight:bold}.billingDetails__phoneInput input{height:35px}.billingDetails__button{margin-top:20px;width:220px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:40px;background-color:#fcb247 !important;border:none !important;font-family:"Poppins",sans-serif}.billingDetails__button:hover{cursor:pointer;-webkit-filter:brightness(1.1);filter:brightness(1.1)}

.shipping{font-family:"Poppins",sans-serif;margin-top:40px}

.shippingDetails{margin-bottom:40px}.shippingDetails__label{font-weight:bold}.shippingDetails__phoneInput input{height:35px}

.paymentSuccess{margin-top:40px;font-family:"Poppins",sans-serif;max-width:800px !important}.paymentSuccess__title{font-size:30px !important;margin-bottom:30px}.paymentSuccess__icon{font-size:100px !important;color:green}.paymentSuccess__button{margin-top:20px;width:220px !important;height:40px !important;background-color:#054bc8 !important;border:none !important}.paymentSuccess__button:hover{cursor:pointer;-webkit-filter:brightness(1.1);filter:brightness(1.1)}

.paymentFailed{margin-top:40px;font-family:"Poppins",sans-serif;max-width:800px !important}.paymentFailed__title{font-size:30px !important;margin-bottom:30px}.paymentFailed__icon{font-size:100px !important;color:red}.paymentFailed__button{margin-top:20px;width:220px !important;height:40px !important;background-color:#054bc8 !important;border:none !important}.paymentFailed__button:hover{cursor:pointer;-webkit-filter:brightness(1.1);filter:brightness(1.1)}

.about{font-family:"Poppins",sans-serif;margin-bottom:20px}.about__imageContainer{width:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-top:40px}.about__image{max-height:500px;border-radius:5px}.about__titleContainer{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;margin-top:50px}.about__paraContainer{width:100%;margin-top:40px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.about__para{width:70%}@media (max-width: 960px){.about__para{width:100%}}

.contact{font-family:"Poppins",sans-serif;margin-bottom:20px}.contact__imageContainer{width:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;margin-top:40px}.contact__image{max-height:500px;border-radius:5px}.contact__titleContainer{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;margin-top:50px}.contact__paraContainer{width:100%;margin-top:40px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.contact__para{text-align:center;width:70%}@media (max-width: 960px){.contact__para{width:100%}}

.appBar{background:#054bc8 !important;font-family:"Poppins",sans-serif}.appBar__brand{color:white !important}.appBar__brand img{width:120px;height:30px}.appBar__link{color:white !important}.appBar__user{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.appBar__user .dropdown-toggle{color:white !important;text-decoration:none !important}.appBar__iconContainer{position:relative}.appBar__badge{position:absolute;left:12px;top:-8px}.appBar__icon{color:white !important;margin-right:10px}.appBar__icon:hover{cursor:pointer}

.footer{width:100%;display:-webkit-flex;display:flex;height:50px;width:100%;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;background-color:#011331;color:white;margin-top:20px;font-family:"Poppins",sans-serif}

.layout{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;min-height:100vh}.layout__header{-webkit-flex:1 1;flex:1 1}

.home{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;overflow-x:hidden}.home__ribbonHeader{margin-top:20px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.home__ribbonHeader h3{font-family:"Poppins",sans-serif;font-weight:bold}@media (max-width: 600px){.home__ribbonHeader{margin-top:0}}.home__ribbon{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;align-items:flex-start;-webkit-justify-content:center;justify-content:center;overflow-x:auto;margin-top:10px;margin-bottom:20px}@media (max-width: 600px){.home__ribbon{margin-bottom:0;margin-top:0}}.home__divider{display:block;width:15%;background-color:black;opacity:0.3;height:1px;margin-top:5px}


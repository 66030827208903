.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.register__logo {
  img {
    width: 100px;
    height: 100px;
  }
}

.register__title {
  margin-top: 40px;
  h3 {
    font-size: 25px;
    font-weight: bold;
  }
}

.register__form {
  width: 40%;
  margin-top: 40px;
  min-width: 300px;
  max-width: 550px;
}

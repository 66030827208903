@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.videoRibbon {
  display: flex;
  overflow-y: hidden;
  padding: 10px 0;
  padding-right: 20px;
}

.videoRibbon__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;

  @include mediaQueryFrom($smallScreen) {
    margin-left: 15px;
  }

  h5 {
    font-family: $primaryFontFamily;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-family: $primaryFontFamily;
    font-size: 16px;
    opacity: 0.7;
  }
}

.videoRibbon__discount {
  text-decoration: line-through;
}

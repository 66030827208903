@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.videos {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.videos__search {
  padding-top: 20px;
  padding-bottom: 20px;
}

.videos__ribbonHeader {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-family: $primaryFontFamily;
    font-weight: bold;
  }

  @include mediaQueryFrom($smallScreen) {
    margin-top: 0;
  }
}

.videos__ribbon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
  margin-bottom: 20px;

  @include mediaQueryFrom($smallScreen) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.videos__divider {
  display: block;
  width: 15%;
  background-color: black;
  opacity: 0.3;
  height: 1px;
  margin-top: 5px;
}

.videos__genre {
  margin-bottom: 30px;
  margin-top: 30px;
  width: 90%;
  overflow-x: auto;
  align-self: center;
  display: flex;
  align-items: flex-start;
}

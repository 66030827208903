@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.dataLoading {
  padding-top: 90px;
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: $lightGreyBackground;
  align-items: flex-start;
  justify-content: center;
}

.dataLoading__itemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dataLoading__spinner {
  color: $primaryCholishnu !important;
  margin-bottom: 40px;
}

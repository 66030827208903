@import '../../globalStyles/variables.scss';
@import '../../globalStyles/mixins.scss';

.individualItem__itemRow {
  padding-top: 20px;
  padding-bottom: 20px;
}

.individualItem__title {
  font-weight: bold;
  @include mediaQueryFrom($smallScreen) {
    margin-top: 20px;
  }
}

.individualItem__price {
  margin-top: 20px;
}

.individualItem__remove {
  margin-top: 10px;
  color: red;

  &:hover {
    cursor: pointer;
  }
}

.individualItem__quantityCol {
  display: flex;
  align-items: flex-end;
  @include mediaQueryFrom($smallScreen) {
    margin-top: 20px;
  }
}

.individualItem__quantity {
  display: flex;
  height: 40px;
  align-items: center;
}

.individualItem__quantityValue {
  margin-right: 20px;
  margin-left: 20px;
}

.individualItem__discount {
  text-decoration: line-through;
}
